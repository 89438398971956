<template>
    <div class="card">
        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="false"
                title="TDS VDS Register"
            />
        </div>
        <div class="my-2 px-2">
            <DateQuerySetter
                btnTitle="Go"
                @onClickGo="onClickToGo"
                class="tds-vds-query-setter"
            >
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-1">
                    <div class="d-flex align-items-center justify-content-between filter-label">
                        <label for="colFormLabel" class="col-form-label">Party: </label>
                        <span class="filter-search-icon cursor-pointer" @click="onOpenContactSearchModal">
                            <i class="fas fa-search"></i>
                        </span>
                    </div>

                    <AsyncSelect
                        placeholder="Select Party"
                        v-model="params.contact_profile_id"
                        :api-service="fetchContactProfiles"
                        :format-label="formatPatientLabel"
                        :reduce="name => name.id"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-1">
                    <label for="colFormLabel" class="col-form-label">Accounting Head</label>
                    <v-select
                        v-model="params.account_head_id"
                        :options="accountHeads"
                        label="name"
                        :reduce="(item) => item.id"
                        placeholder="Select head"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-4 mb-1">
                    <label for="colFormLabel" class="col-form-label">VDS Section Head</label>
                    <v-select
                        v-model="params.vds_rate_id"
                        placeholder="Select VDS Section Head"
                        :options="vdsRateList"
                        :reduce="item => item.id"
                        label="name"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-4 mb-1">
                    <label for="colFormLabel" class="col-form-label">TDS Section Head</label>
                    <v-select
                        v-model="params.tds_rate_id"
                        placeholder="Select TDS Section Head"
                        :options="tdsRateList"
                        :reduce="item => item.id"
                        label="name"
                    />
                </div>
                <div class="col-12 col-sm-6 col-md-4 col-lg-4 mb-1">
                    <label for="colFormLabel" class="col-form-label">Payment Status</label>
                    <v-select
                        v-model="params.payment_status"
                        placeholder="Select Payment Status"
                        :options="paymentStatuses"
                        :reduce="item => item.value"
                        label="name"
                    />
                </div>
            </DateQuerySetter>
        </div>

        <div class="d-flex justify-content-end px-2 mb-2 gap-1">
            <button @click="exportTable" class="btn btn-outline-secondary btn-sm btn-download-invoice waves-effect">
                Export
            </button>
        </div>

        <div class="col-12 px-2 mt-2 mb-4">
            <ListTable 
                :tableItems="tableItems"
            />
        </div>

        <div class="px-2 position-absolute bottom-0 mt-4">
            <Pagination :offset="params.offset" @onPageChange="onPageChange" ref="pagination"/>
        </div>

        <Loader v-if="loading"/>
        
        <ContactDropdownFilter
            v-if="store.state.isModalOpenThree"
            @onSearchContact="onSearchContact"
            :isFilterable="true"
            :companyRoles="companyRoles"
        />
    </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton';
import { inject, onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import Loader from '@/components/atom/LoaderComponent'
import Pagination from '@/components/atom/Pagination'
import ListTable from '@/components/molecule/company/compliance/tds-vds-register/TdsVdsRegisterTable.vue';
import DateQuerySetter from '@/components/atom/DateQuerySetter.vue';
import handleCompliance from '@/services/modules/compliance/compliance';
import handleReport from "@/services/modules/accountingReport";
import ContactDropdownFilter from '@/components/atom/Contact/ContactDropdownFilter.vue'
import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
import handleRole from "@/services/modules/role";
import handleContact from "@/services/modules/contact";
import { computed } from 'vue';
import handleTdsVds from '@/services/modules/procurement/tdsVds'
import handlePurchaseAndSales from "@/services/modules/purchase";
import { reduce } from 'lodash';


const store = useStore();
const route = useRoute();
const router = useRouter();
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const loading = ref(false)
const headId = ref(null);
const contactProfile = ref(null);
const accountHeads = ref([]);
const companyRoles = ref([]);
const tableItems = ref([]);
const params = ref({
    offset: 10,
    tds_rate_id: null,
    vds_rate_id: null,
    payment_status: null,
    account_head_id: null,
    contact_profile_id: null
});
const tdsRateList = ref([]);
const vdsRateList = ref([]);
const paymentStatuses = ref([]);

const token = computed(() => localStorage.getItem('token'));

const {
    fetchTdsVdsRegister
} = handleCompliance();
const { fetchAccountHeads } = handleReport();
const { fetchCompanyDefaultRoles } = handleRole();
const { fetchContactProfiles } = handleContact();
const { formatPatientLabel } = useAsyncDropdownHelper();
const { fetchTdsRateList, fetchVdsRateList } = handleTdsVds();
const { fetchPaymentStatus } = handlePurchaseAndSales();

const onClickToGo = () => {
    getTdsVdsList();
}

const onSearchContact = (contact) => {
    contactProfile.value = contact
}

const onOpenContactSearchModal = () => {
    store.state.isModalOpenThree = true;
}

const setPaginationData = (data) => {
    store.commit('setPaginationData', data)
};

const setPagination = (data) => {
    setPaginationData({
        records: data.total,
        perPage: data.per_page,
        currentPage: data.current_page,
    })
}

const onPageChange = async (page) => {
    let routeQuery = Object.assign({}, route.query)
    routeQuery.page = page
    await router.push({path: route.path, query: routeQuery})
    getTdsVdsList();
}

const getAccountHeads = async () => {
    let res = await fetchAccountHeads(route.params.companyId);
    if (res.status) {
      accountHeads.value = res.data;
    }
}

const getCompanyRoles = async () => {
    const res = await fetchCompanyDefaultRoles(`?company_id=${route.params.companyId}`);
    if (res.status) {
        companyRoles.value = res.data
    }
}

const getTdsRates = async () => {

    let query =  getQuery();
    query += '&with_code=1';

    await fetchTdsRateList(query).then(res => {
        if(res.status){
            tdsRateList.value = res.data;
        }
    }).catch(err => {
        console.log(err)
    })
}

const getVdsRates = async () => {

    let query =  getQuery();
    query += '&with_code=1';

    await fetchVdsRateList(query).then(res => {
        if(res.status){
            vdsRateList.value = res.data;
        }
    }).catch(err => {
        console.log(err)
    })
}

const getPaymentStatus = async () => {

    let query =  getQuery();

    await fetchPaymentStatus(query).then(res => {
        if(res.status){
            paymentStatuses.value = res.data;
        }
    }).catch(err => {
        console.log(err)
    })
}

const exportTable = () => {

    let query = getQuery();
    query += '&is_export=1';
    query += '&_token=' + token.value;

    let url = `${process.env.VUE_APP_BASE_URL}/export/compliance/tds-vds-register${query}`;
    let a = document.createElement("a")
    a.setAttribute("href", url)
    a.click()
}

const getTdsVdsList = async () => {

    const query = getQuery();
    
    loading.value = true

    await fetchTdsVdsRegister(query).then(res => {
        if (!res.status){
            store.commit("resetPagination")
            return
        }

        tableItems.value = res.data.data;
        setPagination(res.data)
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const getQuery = () => {
    let query = `?company_id=${route.params.companyId}`;
    if (route.query.page) query += '&page=' + route.query.page;
    if (route.query.start) query += '&start_date=' + route.query.start;
    if (route.query.end) query += '&end_date=' + route.query.end;
    if (params.value.contact_profile_id) query += '&contact_profile_id=' + params.value.contact_profile_id;
    if (params.value.account_head_id) query += '&account_head_id=' + params.value.account_head_id;
    if (params.value.payment_status) query += '&payment_status=' + params.value.payment_status;
    if (params.value.tds_rate_id) query += '&tds_rate_id=' + params.value.tds_rate_id;
    if (params.value.vds_rate_id) query += '&vds_rate_id=' + params.value.vds_rate_id;
    query += '&offset=' + params.value.offset
    return query;
}

onMounted(() => {
    Promise.all([
        getAccountHeads(),
        getCompanyRoles(),
        getTdsRates(),
        getVdsRates(),
        getPaymentStatus()
    ])
})
</script>

<style>
.tds-vds-query-setter .action-btn-setter {
    margin-bottom: 1.5% !important;
}
</style>